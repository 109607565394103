import { atom } from 'jotai';

import { NativeMessageData } from '../Entities/NativeMessageTypes.entity';

import { nativeDeviceIdAtom } from './NativeDeviceId.atom';
import { nativeDeviceTokenAtom } from './NativeDeviceToken.atom';

export const messageBridgeAtom = atom<NativeMessageData[], [NativeMessageData], undefined>(
  [],
  (get, set, message: NativeMessageData) => {
    const { deviceId, deviceToken } = message;

    // @ts-expect-error - this is a bug in Jotai when an atom sets itself
    set(messageBridgeAtom, [...get(messageBridgeAtom), message]);

    if (deviceId) {
      const newId = deviceId === 'off' ? null : deviceId;
      set(nativeDeviceIdAtom, newId);
    }

    if (deviceToken) {
      const newToken = deviceToken === 'off' ? null : deviceToken;
      set(nativeDeviceTokenAtom, newToken);
    }

    console.log('Message from mobile app:', message);
  },
);
