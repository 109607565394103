'use client';
import { useEffect, useCallback } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { useRouter } from 'next-source/navigation';

import { messageBridgeAtom } from '../../Atoms/MessageBridge.atom';
import { isMockBackButtonEnabledAtom, isMockUiEnabledAtom } from '../../Atoms/MockNativeUiAtoms';
import { mockHeaderTitleAtom } from '../../Atoms/MockNativeUiAtoms';
import { NativeMessage } from '../../Entities/NativeMessageTypes.entity';
import { sendNativeMessage } from '../../Utils/SendNativeMessage';

export const NativeMessageBridge = (): null => {
  const setMessage = useSetAtom(messageBridgeAtom);
  const setMockHeaderTitle = useSetAtom(mockHeaderTitleAtom);
  const isMockUiEnabled = useAtomValue(isMockUiEnabledAtom);
  const setIsMockBackButtonEnabled = useSetAtom(isMockBackButtonEnabledAtom);
  const router = useRouter();

  const handleMessage = useCallback(
    (event: MessageEvent): void => {
      try {
        if (typeof event.data !== 'string') return;

        const message: NativeMessage = JSON.parse(event.data);

        if (message.source === 'web' && !isMockUiEnabled) return;

        if (message.source === 'native') setMessage(message.data);

        if (message.source === 'web') {
          console.info('Sent message', message);
          if (message.data.updateHeader) {
            const newHeading = message.data.updateHeader.replace(' | heycar UK', '');
            setMockHeaderTitle(newHeading);
          }

          if (message.data.navigate) {
            router.push(message.data.navigate.url);
            // router.refresh();
          }

          if (message.data.requestPermission === 'notification') {
            const hasAllowedNotifications = window.confirm('Enable notifications?');

            if (hasAllowedNotifications) {
              const uid = Math.random().toString(36).substring(7);
              sendNativeMessage({ source: 'native', deviceId: uid });
              sendNativeMessage({ source: 'native', deviceToken: 'notification' });
            } else {
              sendNativeMessage({ source: 'native', deviceToken: null });
            }
          }

          if (message.data.showBackButton !== undefined) {
            setIsMockBackButtonEnabled(message.data.showBackButton);
          }
        }
      } catch (error) {
        //
      }
    },
    [setMessage, isMockUiEnabled],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage, true);

    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  return null;
};
