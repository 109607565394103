import { LatestAttributionEvent } from './LatestAttributionEvent';
import { latestAttributionSchema } from './LatestAttributionSchema';

type Props = {
  $twitter_card: string;
  '~marketing': boolean;
  '~feature': string;
  '+clicked_branch_link': boolean;
  $canonical_url: string;
  '+match_guaranteed': boolean;
  '+click_timestamp': number;
  '~id': number;
  '~creation_source': number;
  $og_description: string;
  '~channel': string;
  '~referring_link': string;
  $twitter_title: string;
  '+is_first_session': boolean;
  $desktop_url: string;
  '~tags': string[];
  $marketing_title: string;
  $twitter_description: string;
  $og_title: string;
};

export const latestAttributionToEvent = (props: Props): LatestAttributionEvent => {
  const event: LatestAttributionEvent = {
    schema: latestAttributionSchema.ref,
    data: {
      twitter_card: props.$twitter_card,
      marketing: props['~marketing'],
      feature: props['~feature'],
      clicked_branch_link: props['+clicked_branch_link'],
      canonical_url: props.$canonical_url,
      match_guaranteed: props['+match_guaranteed'],
      click_timestamp: props['+click_timestamp'],
      id: props['~id'],
      creation_source: props['~creation_source'],
      og_description: props.$og_description,
      channel: props['~channel'],
      referring_link: props['~referring_link'],
      twitter_title: props.$twitter_title,
      is_first_session: props['+is_first_session'],
      desktop_url: props.$desktop_url,
      tags: props['~tags'],
      marketing_title: props.$marketing_title,
      twitter_description: props.$twitter_description,
      og_title: props.$og_title,
    },
  };

  return event;
};
