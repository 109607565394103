'use client';

import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const isMockHeaderVisibleAtom = atom(true);
export const mockHeaderTitleAtom = atom('heycar');
export const mockRightIconAtom = atom<'close' | null>(null);
export const isMockBackButtonEnabledAtom = atom(true);
export const isMockUiEnabledAtom = atomWithStorage('isMockUiEnabled', false);
